import React from 'react'
import styled from 'styled-components';
import { SideRight } from "../SideRight";

export const Sidebar = ({ toggle, handleToggle, dataHeader, dataFooter }) => {
  return (
    <>
      <LinkClose toggle={toggle} onClick={handleToggle}>
        <ParentSpan>
          <ChildSpanRight></ChildSpanRight>
          <ChildSpanLeft></ChildSpanLeft>
        </ParentSpan>
      </LinkClose>
      <MenuDrawer toggle={toggle}>
        <SideRight
          dataHeader={dataHeader}
          dataFooter={dataFooter}
        />
      </MenuDrawer>
    </>
  )
}

const MenuDrawer = styled.div`
  opacity: 0;
  position: absolute;
  top: 0;
  right: 0;
  padding-top: 100px;
  padding-bottom: 54.8px;
  min-width: 390px;
  width: 300px;
  padding-left: 60px;
  padding-right: 60px;
  z-index: 5;
  overflow: hidden;
  height: 100%;
  overflow-y: scroll;
  box-sizing: border-box;
  transition: transform 0.65s cubic-bezier(0.2, 0.8, 0.25, 1),
    opacity 0.65s cubic-bezier(0.2, 0.8, 0.25, 1);
  transform: translateX(400px);
  ${(props) =>
    props.toggle &&
    `
    opacity: 1;
    transition: transform .65s cubic-bezier(.2,.8,.25,1), opacity .65s cubic-bezier(.2,.8,.25,1);
    transform: translateX(0);
    `}
  @media (max-width: 450px) {
    min-width: 78vw;
    width: 78vw;
  }
`;
const LinkClose = styled.a`
  visibility:${props => props.toggle ? "visible" : "hidden"};
  cursor: pointer;
  position: fixed;
  z-index: 10000;
  top: 50%;
  opacity: ${(props) => (props.toggle ? 1 : 0)};
  right: 0;
  transition: opacity 0.5s;
  transition-delay: 0.3s;
  transform: translateY(-50%) translateX(-389px)  !important;
  transition: transform 0.2s ease-in-out, visibility 0.5s,;

  &:hover {
    @media (min-width: 650px){
      transform: scale(1.3) translateY(-50%) translateX(-300px) !important;
    }
    transform: scale(1.3);
    transition: transform 0.5s ease-in-out;
    /* transition: right 0.3s ease-in-out; */
  }
  @media (max-width: 450px) {
    top: 50%;
    right: 78vw;
    transform: translateX(0) !important;
  }
  &::before {
    background: linear-gradient(to bottom right, #a20432, #fd0049) !important;
    box-shadow: 0 16px 44px rgb(0 0 0 / 3 0%);

    position: absolute;
    top: 50%;
    left: 50%;
    width: 50px;
    height: 50px;
    margin: -25px 0 0 -25px;
    border-radius: 50%;
    content: "";
  }
`;

const ParentSpan = styled.span`
  transform-style: preserve-3d;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%) translateZ(0);
  width: 20px;
  height: 20px;
  display: block;
  overflow: hidden;
`;

const ChildSpanRight = styled.span`
  box-sizing: border-box;
  transform: translateY(-30px) translateX(30px) rotate(45deg);
  height: 100%;
  width: 2px;
  background: #fff;
  backface-visibility: hidden;
  position: absolute;
  border-radius: 5px;
  left: 9px;
  transform: translateY(0px) translateX(0px) rotate(45deg);
`;

const ChildSpanLeft = styled.span`
  box-sizing: border-box;
  transform: translateY(-30px) translateX(30px) rotate(-45deg);
  height: 100%;
  width: 2px;
  background: #fff;
  backface-visibility: hidden;
  position: absolute;
  border-radius: 5px;
  left: 9px;
  transform: translateY(0px) translateX(0px) rotate(-45deg);
`;