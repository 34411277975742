import { navigate } from "gatsby"

export const redirectPage = ({ url, type, link_type, uid }) => {
  if (link_type === "Web") {
    // typeof window !== "undefined" && (window.location.href = url)
    typeof window !== "undefined" && window.open(url, '_blank');
  }
  if (link_type === "Document") {
    type==="homepage" ? navigate(`/`) : type==="landing_page" ? navigate(`/${uid}`) : navigate(`/${type}`)
  }
  if (link_type === "Media") {
    typeof window !== "undefined" && window.open(url);
  }
  else typeof window !== "undefined" && (window.location.href = "#")
}