import React from 'react'
import styled from 'styled-components'
import { Text } from '../../components/Text/Text'
import { Button } from '../../components/Button/Button'
import { Col, Row } from 'react-styled-flexboxgrid'
import { Address } from '../../components/Address/Address'
import { List } from '../../components/List/List'
import LogoDrapper from '../../images/logo-small.svg'
import PropTypes from 'prop-types';
// import facebook from '../../images/facebook.svg'
// import linkedin from '../../images/linkedin.svg'
// import twitter from '../../images/twitter.svg'
// import instagram from '../../images/instagram.svg'
import { color, typography, spacing } from '../../shared/style'
import { redirectPage } from '../../hooks/redirect'

const FooterContainer = ({ data}) => {
  const listAdrress = data?.address;
  // const section = data?.section
  const dataLocation = {...data?.body1[0], items: data?.body1[0]?.items?.sort((a, b) => a?.city?.document?.data?.city?.text.localeCompare(b?.city?.document?.data?.city?.text))}

  return (
    <FooterWrapper>
      <FooterSection>
        {/* info */}
        <FooterBlockLeft xs={6} sm={5} md={4}>
          <RowRight>
            <LogoWrapper xs={3} sm={2} md={2}>
              <Logo src={data?.site_logo?.url || LogoDrapper} alt="aa"></Logo>
            </LogoWrapper>
            <InforWrapper xs={12} sm={12} md={10}>
              <Description>
              <TextDescription
                color={color.netraul.black100}
                font={typography.type.primary}
                weight={typography.weight.regular}
                size={typography.size.s3}
                lineHeight={26}
              >
                {data?.description?.text}
              </TextDescription>
              </Description>
              <Span />
              {listAdrress && listAdrress?.map(((item, i) => (
                <Address
                  key={i}
                  item={item}
                  underlineEmail={false}
                />
              )))}
              <ColButtonMobile xs={0} sm={0} md={5}>
                {data?.button_label?.text && (
                  <ButtonExtendedMobile 
                  type="primary" 
                  onClick={() => 
                    redirectPage({
                      url: data?.button_link?.url,
                      type: data?.button_link?.type,
                      link_type: data?.button_link?.link_type
                    })
                  }>{data?.button_label?.text}</ButtonExtendedMobile>
                )}
              </ColButtonMobile>
            </InforWrapper>
          </RowRight>
        </FooterBlockLeft>
        <FooterBlockRight xs={6} sm={7} md={8}>
          {/* wwhat we do */}
          <FooterBlockRightSection>
            <Col className="col-left" xs={12} md={8}>
              <List
                data={data?.body[0]}
                tag='h5'
                color={color.dark.dark100}
                colorItem={color.dark.dark90}
                weightHeading={typography.weight.regular1}
                sizeItem={typography.size.s3}
                weightItem={typography.weight.regular}
                flexDirection="row"
                flexWrap="wrap"
                height="120px"
                heightMobile="140px"
                widthMobile="50%"
                numberOfCol={3}
              />
            </Col>
            <ColButton xs={0} sm={0} md={4}>
              {data?.button_label?.text && (
                <ButtonExtended
                onClick={() => 
                  redirectPage({
                    url: data?.button_link?.url,
                    type: data?.button_link?.type,
                    link_type: data?.button_link?.link_type
                  })
                }
                type="primary"
              >{data?.button_label?.text}</ButtonExtended>
              )}
            </ColButton>
          </FooterBlockRightSection>
          {/* locations */}
          <FooterBlockRightSection>
            <Col xs={12} md={12}>
              <List
                data={dataLocation}
                tag='h5'
                color={color.dark.dark100}
                colorItem={color.dark.dark90}
                weightHeading={typography.weight.regular1}
                sizeItem={typography.size.s3}
                weightItem={typography.weight.regular}
                flexDirection="row"
                flexWrap="wrap"
                height="200px"
                heightMobile="330px"
                widthMobile="50%"
                numberOfCol={5}
              />
            </Col>
          </FooterBlockRightSection>
        </FooterBlockRight>
      </FooterSection>
      {/* /location responsived mobile*/}
      <FooterSection>
        <FooterResponsive>
          <List
            data={dataLocation}
            tag='h5'
            color={color.dark.dark100}
            colorItem={color.dark.dark90}
            weightHeading={typography.weight.regular}
            sizeItem={typography.size.s3}
            weightItem={typography.weight.regular}
            height="200px"
            heightResponsive="200px"
            flexDirection="row"
            numberOfCol={4}
          />
        </FooterResponsive>
      </FooterSection>
      {/* copyright */}
      <FooterSection>
        <Border></Border>
        <FooterSectionContent>
          <CopyRight xs={12} sm={12} md={4}>
            <StyleCopyright>
              <Text
                color={color.netraul.black70}
                font={typography.type.primary}
                weight={typography.weight.regular}
                size={typography.size.s2}
                lineHeight={22}
              >
                {data?.footer_copyright?.text}
              </Text>
            </StyleCopyright>
          </CopyRight>
          <Privacy xs={12} sm={12} md={6}>
            <List
              textTranform='lowercase'
              color={color.netraul.black100}
              colorItem={color.netraul.black90}
              sizeHeading={typography.size.s2}
              weightHeading={typography.weight.bold}
              sizeItem={typography.size.s2}
              weightItem={typography.weight.regular}
              flexDirection='row'
              justifyContent='flex-start'
              justifyContentMobile='center'
              height="100%"
              noLineHeight={true}
              lineHeight={22}
              border={true}
              data={data?.body2[0]}
            />
          </Privacy>
          <Contact xs={12} sm={12} md={2}>
            <List
              textTranform='lowercase'
              isIcon={true}
              color={color.netraul.black100}
              colorItem={color.netraul.black70}
              sizeHeading={typography.size.s2}
              weightHeading={typography.weight.bold}
              sizeItem={typography.size.s2}
              flexWrap="nowrap"
              weightItem={typography.weight.regular}
              flexDirection='row'
              justifyContentMobile='center'
              justifyContent='flex-end'
              display="flex"
              height="100%"
              noLineHeight={true}
              data={data?.body3[0]}
              type="social"
            />
          </Contact>
        </FooterSectionContent>
      </FooterSection>
    </FooterWrapper>
  )
}

export default FooterContainer;


// props

FooterContainer.propTypes = {
  logo: PropTypes.node,
};

FooterContainer.defaultProps = {
  
};

// style
const FooterWrapper = styled.footer`
  background-color:${color.primary.blue05};
  padding: 90px 71px 0px 0;
  max-width: 1500px;
  box-sizing: border-box;
  @media (max-width: 1300px) {
    padding: 90px 40px 0px 70px;
  }
  @media (max-width: 1000px) {
    padding: 90px 40px 0px 40px;
  }
  @media (max-width: 649px) {
    padding: 60px 20px 0px 20px;
  }
`;

const FooterSection = styled(Row)`
  @media (max-width: 1000px) and (min-width: 650px) {
    
  }
  @media (max-width: 649px) {
    display: flex;
    flex-direction: column;
    margin-left: 0;
    flex-wrap: nowrap;
  }
`;
// block left
const FooterBlockLeft = styled(Col)`
  display: flex;
  align-items: flex-start;
  @media (max-width: 1000px) and (min-width: 650px) {
    flex-direction: column;
  }
  @media (max-width: 649px) {
    max-width: 100vw;
  }
`;

const RowRight = styled(Row)`
  flex-wrap: nowrap;
  @media (max-width: 1300px) {
    flex-wrap: wrap;
    align-items: flex-start;
  }
  
  @media (max-width: 650px) {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    /* margin-bottom: 50px; */
  }
`;

const LogoWrapper = styled(Col)`
  height: fit-content;
  margin: 0 29px 40px 39px;
  padding: 0;
  @media (max-width: 1000px) {
    position: inherit;
  }
  @media (max-width: 650px) {
    margin: 0 0 40px 0;
    display: flex;
    justify-content: center;
  }
`;

const Logo = styled.img`
  width: 64px;  
  height: auto;
  max-width: 64px;
`;

const InforWrapper = styled(Col)`
  padding-left: 0;
  padding-right: 0;
  div {
    padding-left: 0;
  }
  @media (max-width: 1000px) {
    margin-left: 0;
  }
  @media (max-width: 650px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 50px;
    border-bottom: 1px solid ${color.netraul.black10};
  }
`;

const Span = styled.span`
border: 0;
border-top: 3px solid ${color.netraul.black10};
width: 40px;
margin: 17px 0;
display: block;
`
const Border = styled.span`
  border: 0;
  border-top: 1px solid ${color.netraul.black10};
  display: block;
  width: 100%;
  @media (min-width: 1300px) {
    margin-left: 131px;
  }
`

//block right
const FooterBlockRight = styled(Col)`
  @media (max-width: 649px) {
    max-width: 100%;
    margin-top: 30px;
  }
  @media (max-width: 1100px) and (min-width: 1000px) {
    padding: 0 20px;
  }
`;

const FooterBlockRightSection = styled(Row)`
  &:first-child {
    border-bottom: 1px solid ${color.netraul.black10};
    margin-bottom: 37px;
    @media (max-width: 1000px) and (min-width: 650px) {
      border-bottom: none;
    }
  }
  &:last-child {
    @media (max-width: 1000px) and (min-width: 650px) {
    display: none;
  }
  }
  .col-left {
    @media (max-width: 1200px) and (min-width: 1024px) {
      width: 100%;
      max-width: 100%;
      flex-basis: 100%;
    }
  }
`;
const Description = styled.div`
  display: flex;
  @media (min-width: 650px) {
    width: 236px;
  }
`
const TextDescription = styled(Text)`
  
  @media (max-width: 649px) {
    text-align: center;
  }
`;

const ColButton = styled(Col)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  max-width: 100%;
`

const ColButtonMobile = styled(Col)`
  display:none;
  @media (max-width: 649px) {
    display: block;
    max-width: 100%;
  }
`

const ButtonExtended = styled(Button)`
    width: fit-content;
    margin-bottom: 10px;
    white-space: nowrap;
    @media (max-width: 649px) {
        display: none;
    }
`
const ButtonExtendedMobile = styled(Button)`
    border-radius: ${spacing.borderRadius.large}px;
    height: 65px;
    border-radius: 40px;
    white-space: nowrap;
    display: none;
    width: fit-content;
    @media (max-width: 649px) {
        display: block;
    }
`

const FooterSectionContent = styled(Col)`
  width: 100%;
  display: flex;
  /* border-top: 1px solid ${color.netraul.black10}; */
  padding: 34px 0 26px 0;
  div {
    padding-left:0 ;
  }
  @media (max-width: 1000px) {
    padding: 34px 0 26px 0;
  }
  @media (max-width: 1100px) {
    flex-direction: column;
    align-items: center;
    height: 100%;
  }
`;

const FooterResponsive = styled.div`
  display: none;
  @media (max-width: 1000px) and (min-width: 650px) {
    display: block;
    width: 100%;
    border-top: 1px solid ${color.netraul.black10};
    padding-top: 37px;
    padding-left: 8px;
  }
`

const CopyRight = styled(Col)`
  display: flex;
`;
const Privacy = styled(Col)`
  display: flex;
  max-width: 100%;
`;
const Contact = styled(Col)`
  display: flex;
  height: fit-content;
  @media (max-width: 1100px) {
    width: 100%;
  }
`;
const StyleCopyright = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;
  @media (min-width: 1100px) {
    padding-left: 134px !important;
  }
  @media (max-width: 1300px) {
    padding-left: 0 !important;
  }
`;