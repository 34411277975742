import React from 'react'
import { Box } from 'rebass/styled-components'
import styled from 'styled-components'
import ListItem from "./ListItem"
import PropTypes from 'prop-types'
import { Text } from '../Text/Text'
import { color } from '../../shared/style'
import {redirectPage} from  '../../hooks/redirect'

const FONT = {
  Oxygen: 'Oxygen',
  Alternate: 'Alternate Gothic No1 D',
}

export const List = ({ children, items, lineHeight,data, ...props }) => {
  return (
    <>
      <TextHeading {...props}>
        <TextExtend
          color={props.color}
          font={FONT.Alternate}
          weight={props.weightHeading}
          tag={props.tag}
          lineHeight={36}
        >
          {!data ? items?.label?.text || items?.heading : data?.primary?.title?.text}
        </TextExtend>
      </TextHeading>
      <BoxUl as="ul" px={0} pb={3} {...props}>
        {items && !data && items.submenu && items?.submenu?.document?.data?.items?.map((item, i) => //what list
          <ListItem
            key={i}
            onClick={()=>{
              redirectPage({
                url: item?.link?.url,
                type: item?.link?.type,
                link_type: item?.link?.link_type
              })
            }}
            noLineHeight={props.noLineHeight}
            isSeemore={false}
            {...props}
          >
            {item?.label[0]?.text || item?.label?.text}
          </ListItem>
        )}
        {data && !props.isIcon && data?.items?.map((item, i) => //what list
          <ListItem
            key={i}
            onClick={()=>{
              redirectPage({
                url: item?.link?.url,
                type: item?.link?.type || item?.city?.document?.uid,
                link_type: item?.link?.link_type || "Document",
                uid: item?.link?.uid,
              })
            }}
            lineHeight={lineHeight}
            noLineHeight={props.noLineHeight}
            {...props}
          >
            {item?.label?.text || item?.city?.document?.data?.city?.text}
          </ListItem>
        )}
        {data && props.isIcon && data.items.map((item, i) => //location list
          <ListItem
            key={i}
            // to={item.link.url}
            onClick={()=>{
              redirectPage({
                url: item?.link?.url,
                type: item?.link?.type,
                link_type: item?.link?.link_type || "Document"
              })
            }}
            noLineHeight={props.noLineHeight}
            isSeemore={false}
            lineHeight={lineHeight}
            {...props}
          >
            {props.isIcon ? item.icon.url : item.label}
          </ListItem>
        )}
        {props.seemore &&
          <ListItem
            noLineHeight={props.noLineHeight}
            colorItemSeemore={color.secondary.danube}
            isSeemore={props.seemore}
            {...props}>
            see more
          </ListItem>
        }
        {children}
      </BoxUl>
    </>
  )
}
const TextExtend = styled(Text)`
  font-size: 36px !important;
`
const BoxUl = styled(Box)`
  
  padding-left: ${props => !props.numberOfCol && "0.5rem"};
  height: fit-content;
  display: flex;
  flex-direction: ${props => props.flexDirection && props.flexDirection};
  flex-wrap: ${props => props.flexWrap && props.flexWrap};
  justify-content: ${props => props.justifyContent && props.justifyContent};
  width: 100%;
  @media (max-width: 1240px) {
    justify-content: ${props => props.justifyContentMobile && props.justifyContentMobile};
  }
  @media (max-width: 1000px) and (min-width: 650px){
    /* flex-wrap: nowrap; */
    /* height: ${props => props.heightResponsive || 'auto'}; */
    /* display: flex;*/
    justify-content: ${props => props.justifyContentMobile && props.justifyContentMobile};
    /* justify-content: space-between;  */
  }
  @media (max-width: 649px) {
    /* height: ${props => props.heightMobile && props.heightMobile}; */
    justify-content: ${props => props.justifyContentMobile && props.justifyContentMobile};
  }
`

const TextHeading = styled.div`
  text-align: start !important;
  margin-bottom: 26px;
  text-transform: ${props => (props.textTranform && props.textTranform) || 'uppercase'};
`

List.propTypes = {
  height: PropTypes.string,
  flexWrap: PropTypes.oneOf(["wrap", "nowrap"]),
  flexDirection: PropTypes.oneOf(["row", "column"]),
  justifyContent: PropTypes.oneOf(["space-evenly", "space-between", "flex-start"]),
};

List.defaultProps = {
  height: '150px',
  flexWrap: 'wrap',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  items: {
    heading: 'Explore',
    items: [
      {
        label: 'Venture',
        link: '/'
      },
      {
        label: 'Academy',
        link: '/'
      },
      {
        label: 'Partnership',
        link: '/'
      },
      {
        label: 'Venture',
        link: '/'
      },
      {
        label: 'Academy',
        link: '/'
      },
      {
        label: 'Partnership',
        link: '/'
      },
      {
        label: 'Academy',
        link: '/'
      },
      {
        label: 'Partnership',
        link: '/'
      },
    ]
  }
};