import React from "react";
import styled from "styled-components";
import { SideMenu } from "./SideMenu";
import { Address } from "../Address/Address";
import { color, typography } from "../../shared/style";
import { Text } from "../Text/Text";
import { Link } from "../Link/Link";

export const SideRight = ({ dataHeader, dataFooter }) => {
    const address = dataFooter?.address;
    const navigation = dataHeader?.main_navigation;
    const cities = dataHeader?.city;
    return (
        <MenuWrapper>
            <SideMenu data={navigation} menuCities={cities}/>
            {address && address?.map(((item, i) => (
                <Address
                    key={i}
                    item={item}
                    isMenu={true}
                />
            )))}

            <Text
                color={color.primary.white}
                font={typography.type.primary}
                weight={typography.weight.bold}
                lineHeight={26}
            >Mail us at</Text>
            <LinkCustom to={`mailto:${address[1]?.email.text}`}
                color={color.primary.white} lineHeight={26} size={typography.size.s3}>{address[1]?.email.text}</LinkCustom>
        </MenuWrapper>
    );
};

const MenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;
const LinkCustom = styled(Link)`
  font-size: 14px;
`