import React from 'react'
import { Helmet } from 'react-helmet'

const SEO = ({ data }) => {
  const og = data?.find((item) => item.slice_type === "open_graph");
  const general = data?.find((item) => item.slice_type === "general_card");
  const twitter = data?.find((item) => item.slice_type === "twitter_card");

  return (
    <Helmet>
      {/* <script
        type="text/javascript"
      >
        {
          `
          var _iub = _iub || [];
          _iub.csConfiguration = {
            "askConsentAtCookiePolicyUpdate": true,
            "countryDetection": true,
            "enableLgpd": true,
            "enableUspr": true,
            "gdprAppliesGlobally": false,
            "lang": "en",
            "lgpdAppliesGlobally": false,
            "perPurposeConsent": true,
            "siteId": 1882173,
            "whitelabel": false,
            "cookiePolicyId": 86408715,
            "banner": {
              "acceptButtonCaptionColor": "white",
              "acceptButtonColor": "#0073CE",
              "acceptButtonDisplay": true,
              "backgroundColor": "white",
              "closeButtonRejects": true,
              "customizeButtonCaptionColor": "#4D4D4D",
              "customizeButtonColor": "#DADADA",
              "customizeButtonDisplay": true,
              "explicitWithdrawal": true,
              "listPurposes": true,
              "position": "float-top-center",
              "rejectButtonDisplay": true,
              "showPurposesToggles": true,
              "textColor": "black"
            }
          };
          `
        }
      </script>
      <script type="text/javascript" src="//cdn.iubenda.com/cs/gpp/stub.js"></script>
      <script type="text/javascript" src="//cdn.iubenda.com/cs/iubenda_cs.js" charset="UTF-8" async></script> */}
      <title>{general?.primary?.title?.text}</title>
      <meta name="description" content={general?.primary?.description?.text} />
      <link rel='shortcut icon' href={general?.primary?.favicon?.url} />
      <meta name="robots" content={general?.primary?.robot?.text} />
      <meta name="googlebot" content={general?.primary?.googlebot?.text} />
      <meta name="bingbot" content={general?.primary?.bingbot?.text} />
      <meta name="twitter:card" content={twitter?.primary?.card_type} />
      <meta property="og:title" content={og?.primary?.og_title?.text} />
      <meta property="og:site_name" content={og?.primary?.og_site_name?.text} />
      <meta property="og:url" content={og?.primary?.og_url?.url} />
      <meta property="og:description" content={og?.primary?.og_description?.text} />
      <meta property="og:type" content={og?.primary?.og_type?.text} />
      <meta property="og:image" content={og?.primary?.og_image?.url} />
      <meta property="og:image:width" content={og?.primary?.og_image_width?.text} />
      <meta property="og:image:height" content={og?.primary?.og_image_height?.text} />
      <meta property="og:locale" content={og?.primary?.og_locale?.text} />

    </Helmet>
  )
}

export default SEO
