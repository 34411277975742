import React from "react";
import styled from "styled-components";
import { Button } from "../../components/Button/Button";
import { HomeLogo } from "./HomeLogo";
import { HomeMenu } from "./HomeMenu";
import logo from "../../images/logo.svg";
import { color, spacing } from "../../shared/style";
import PropTypes from "prop-types";
import more from "../../images/more.svg";
import { redirectPage } from "../../hooks/redirect";
// import { navigate } from "gatsby";

const StyledHeader = styled.header`
  background: #0E2C51;
  z-index: 10;
  backdrop-filter: blur(35px);
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* position: fixed; */
  top: 0;
  width: 100%;
`;
const ButtonExtended = styled(Button)`
  border-radius: ${spacing.borderRadius.large}px;
  padding: 12px 45px;
  height: 50px;
  white-space: nowrap;
  color: ${color.netraul.black90};
  font-size: 16px;
  @media (max-width: 1200px) {
    display: none;
    padding: 12px 25px;
  }
`;
const MoreMenu = styled.div`
  display: none !important;
  @media (max-width: 1200px) {
    cursor: pointer;
    color: ${color.primary.blue80};
    width: 30px;
    display: block !important;
    &:hover {
      color: ${color.primary.blue};
    }
  }
`;
const Nav = styled.nav`
  padding: 0px ${spacing.padding.medium}px;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;
const WrapMenu = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;
const IconMore = styled.img``;
export const Header = ({ logo, menuList, toggleLayout, data }) => {
  const navigation = data?.main_navigation;
  const cities = data?.city;
  return (
    <StyledHeader>
      <Nav>
        <WrapMenu>
          <HomeLogo logo={data?.site_logo?.url || logo} />
          <HomeMenu menuList={navigation} menuCities={cities}/>
        </WrapMenu>
        {data?.button_label?.text && (
          <ButtonExtended
          onClick={() => 
            redirectPage({
              url: data?.button_link?.url,
              type: data?.button_link?.type,
              link_type: data?.button_link?.link_type
            })
          }
          type="outline_primary"
        >
          {data?.button_label?.text}
        </ButtonExtended>
        )}
        <MoreMenu onClick={toggleLayout}>
          <IconMore src={more}></IconMore>
        </MoreMenu>
        {/* for responsive */}
      </Nav>
    </StyledHeader>
  );
};

Header.propTypes = {
  menuList: PropTypes.arrayOf(
    PropTypes.shape({
      link: PropTypes.string,
      label: PropTypes.shape({
        text: PropTypes.string,
      }),
      subMenu: PropTypes.arrayOf(
        PropTypes.shape({
          link: PropTypes.string,
          label: PropTypes.string,
        })
      ),
    })
  ),
  logo: PropTypes.node,
};

Header.defaultProps = {
  menuList: [
    { link: "/", label: { text: "Home" } },
    {
      link: "", //có thể thêm or
      label: {
        text: "Explore",
      },
      subMenu: [
        {
          link: "/about",
          label: "Sub menu test width is too long long long long long",
        },
        { link: "/about", label: "subMenu2" },
        { link: "/about", label: "subMen3" },
      ],
    },
    { link: "/about", label: { text: "Venture" } },
    { link: "/about", label: { text: "Services" } },
    { link: "/about", label: { text: "Company" } },
  ],
  logo: logo,
};
