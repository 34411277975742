import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { useModal } from "../hooks/useModal";

const AppContext = createContext({
  toggle: null,
  setToggle: null,
  open: null,
  handleOpenModal: null,
  handleToggleLayout: null
});
export const AppProvider = ({ children }) => {
  const [toggle, setToggle] = useState(false);

  const { open, setOpen } = useModal();


  const handleOpenModal = useCallback(() => {
    setOpen(!open)
  }, [open, setOpen])

  const handleToggleLayout = useCallback(() => {
    setToggle(!toggle);
  }, [toggle, setToggle])

  const contextValue = useMemo(
    () => ({
      toggle,
      open,
      setToggle,
      handleOpenModal,
      handleToggleLayout,
    }),
    [toggle, open, setToggle, handleToggleLayout, handleOpenModal]
  );
  return (
    <AppContext.Provider value={contextValue}>
      {children}
    </AppContext.Provider>
  );
};

export function withAppContext(Component) {
  return function WrapperComponent(props) {
    return (
      <AppProvider>
        <Component {...props} />
      </AppProvider>
    );
  };
}

export const useApp = () => useContext(AppContext);
export default useApp;