import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import { color, typography } from "../../shared/style";
export const StyledButton = styled.button`
  font-family: "Oxygen";
  position: relative;
  cursor: pointer;
  outline: none;
  font-size: ${(props) =>
    props.size === SIZE.small
      ? typography.size.s2
      : props.size === SIZE.medium
      ? typography.size.s3
      : typography.size.s4}px;
  border-radius: ${(props) => (props.radius ? props.radius : 40)}px;
  padding: ${(props) =>
    props.size === "small"
      ? "5px 10px"
      : props.padding === "medium"
      ? "8px 16px"
      : "20px 45px"};
  font-weight: ${typography.weight.bold};
  transition-duration: 0.5s;

  ${(props) =>
    props.disabled &&
    `   
        opacity: 0.7;
        border: 1px solid gray;
        cursor: not-allowed !important;
        &:hover {
          transform: none;
        }
    `}
  ${(props) =>
    props.type === TYPE.primary &&
    `
        color: ${color.primary.white};
        background: ${color.primary.blue};
        border: 1px solid ${color.primary.blue};
        ${
          !props.isLoading &&
          !props.disabled &&
          `
            &:hover {
              color: ${color.primary.blue};
              background: ${color.primary.white};
              border: 1px solid ${color.primary.blue};}
            `
        }
        }
    `}
    ${(props) =>
    props.type === TYPE.secondary &&
    `
        color: ${color.primary.white};
        background: ${color.secondary.emerald};
        border: 1px solid ${color.secondary.emerald};
        ${
          !props.isLoading &&
          !props.disabled &&
          `
        &:hover {
              color: ${color.secondary.emerald};
              background: ${color.primary.white};
              border: 1px solid ${color.secondary.emerald};
            `
        }
        }
    `}
    ${(props) =>
    props.type === TYPE.outline_primary &&
    `
        color: ${color.primary.blue};
        background: ${color.primary.white};
        border: 1px solid ${color.primary.white};
        ${
          !props.isLoading &&
          !props.disabled &&
          `
        &:hover {
              color: ${color.primary.white};
              background: ${color.primary.blue};
              border: 1px solid ${color.secondary.blue};
            `
        }
        }
    `}
    ${(props) =>
    props.type === TYPE.tertiary &&
    `
        color: ${color.netraul.black100};
        background: ${color.primary.yellow};
        border: 1px solid ${color.primary.yellow};
        ${
          !props.isLoading &&
          !props.disabled &&
          `
        &:hover {
              color: ${color.netraul.black100};
              background: ${color.primary.white};
              border: 1px solid ${color.secondary.yellow};
            `
        }
        }
    `}
    ${(props) =>
    props.type === TYPE.transparent &&
    `
        color: ${color.primary.white};
        background: transparent;
        border: 1px solid ${color.primary.white};
        ${
          !props.isLoading &&
          !props.disabled &&
          `
        &:hover {
              color: ${color.primary.blue};
              border: 1px solid ${color.primary.blue};
              background: ${color.primary.white};
            `
        }
        }
    `}
    ${(props) =>
    props.type === TYPE.lightblue &&
    `
        color: ${color.primary.white};
        background: ${color.secondary.danube};
        border: none;
        ${
          !props.isLoading &&
          !props.disabled &&
          `
        &:hover {
              color: ${color.secondary.danube};
              border: none;
              background: ${color.primary.white};
            `
        }
        }
    `}
    ${(props) =>
    props.isLoading &&
    `
        cursor: progress !important;
        opacity: 0.7;
        ${Loading} {
          transition: transform 700ms cubic-bezier(0.175, 0.885, 0.335, 1.05);
          transform: translate3d(0, -50%, 0);
          opacity: 1;
        }
        ${Text} {
          transform: scale3d(0, 0, 1) translate3d(0, -100%, 0);
          opacity: 0;
        }
        &:hover {
          transform: none;
        }
      `}
`;
const SIZE = {
  small: "small",
  medium: "medium",
  lagre: "lagre",
};
const TYPE = {
  primary: "primary",
  secondary: "secondary",
  tertiary: "tertiary",
  outline_primary: "outline_primary",
  transparent: "transparent",
  lightblue: "lightblue",
  // outline_secondary: 'outline_secondary',
};
const Loading = styled.span`
  font-size: ${typography.size.s0};
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  opacity: 0;
`;
const Text = styled.span`
  display: inline-block;
  vertical-align: top;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  overflow: hidden;
  &:hover {
    text-decoration: ${(prop) => prop.textDecoration || "unset"};
    text-decoration-color: ${(prop) => prop.colorHover || "unset"};
  }
`;

const Img = styled.img``;

export const Button = ({
  type,
  disable,
  isLoading,
  size,
  onClick,
  icon,
  ...props
}) => {
  return (
    <StyledButton
      onClick={onClick}
      className={props.className}
      type={type}
      disabled={disable}
      isLoading={isLoading}
      size={size}
    >
      {isLoading && <Loading>Loading...</Loading>}
      <Text>{props.children}</Text>
      {icon && <Img src={icon}></Img>}
    </StyledButton>
  );
};
Button.propTypes = {
  children: PropTypes.string,
  type: PropTypes.oneOf(Object.values(TYPE)),
  disable: PropTypes.bool,
  isLoading: PropTypes.bool,
  size: PropTypes.oneOf(Object.keys(SIZE)),
};

Button.defaultProps = {
  type: TYPE["primary"],
  size: SIZE["medium"],
};
