import React from 'react'
import styled from "styled-components";
import PropTypes from 'prop-types';
import { color, typography } from '../../shared/style';
const FONT = {
  Oxygen: 'Oxygen',
  Alternate: 'Alternate Gothic No1 D',
}
const TAG = {
  p: "p",
  span: "span",
  h2: "h2",
  h3: "h3",
  h4: "h4",
  h5: "h5",
}
const CAPITAL = {
  uppercase: "uppercase",
  lowercase: "lowercase",
  capitalize: "capitalize",
}
const Span = styled.span`
  cursor: default;
  white-space: break-spaces;
  word-break: break-word;
  text-transform: ${props => props.capital};
  text-align: ${props => props.align};
  padding: 0;
  margin: 0;
  font-weight: ${props => props.weight};
  font-size: ${props => props.size ? props.size : props.tag === TAG.h2 ? typography.size.extracode : props.tag === TAG.h3 ? typography.size.code : props.tag === TAG.h4 ? typography.size.l3 : props.tag === TAG.h5 ? typography.size.l1 : typography.size.s3}px;
  color: ${props => props.color || color.netraul.black};
  font-family: ${props => props.font || FONT.Alternate};
  line-height: ${props => props.lineHeight ? props.lineHeight : props.tag === TAG.h2 ? typography.size.extracode : props.tag === TAG.h3 ? typography.size.code : props.tag === TAG.h4 ? typography.size.l3 : props.tag === TAG.h5 ? typography.size.l1 : 26}px;
  @media (max-width: 1000px) and (min-width: 650px) {
    font-size: ${props => props.size ? props.size : props.tag === TAG.h3 ? typography.size.l4 : props.tag === TAG.h4 ? typography.size.l2 : props.tag === TAG.h5 ? typography.size.l1 : typography.size.s3}px !important; // todo resize font size text with screen width (just only for h3 code - l3)
    line-height: ${props => props.lineHeight ? props.lineHeight : props.tag === TAG.h3 ? typography.size.l4 : props.tag === TAG.h4 ? typography.size.l2 : props.tag === TAG.h5 ? typography.size.l1 : 26}px; // todo resize font size text with screen width (just only for h3 code - l3)
  }
  @media (max-width: 649px) {
    line-height: ${props => props.lineHeight ? props.lineHeight : props.tag === TAG.h3 ? typography.size.l4 : props.tag === TAG.h4 ? typography.size.l1 : props.tag === TAG.h5 ? typography.size.m3 : 26}px; // todo resize font size text with screen width (just only for h3 code - l3)
    font-size: ${props => props.size ? props.size : props.tag === TAG.h3 ? typography.size.l4 : props.tag === TAG.h4 ? typography.size.l1 : props.tag === TAG.h5 ? typography.size.m3 : typography.size.s3}px !important; // todo resize font size text with screen width (just only for h3 code - l3)
  }
`
const P = styled.p`
  cursor: default;
  text-align: ${props => props.align};
  text-transform: ${props => props.capital};
  font-weight: ${props => props.weight};
  font-size: ${props => props.size || 16}px;
  padding: 0;
  margin: 0;
  color: ${props => props.color || color.primary.black};
  font-family: ${props => props.font || FONT.Alternate};
  line-height: ${props => props.lineHeight ? props.lineHeight + "px" : "normal"};
  letter-spacing: 0.4px;
`
export const Text = ({ weight, size, color, font, tag, capital, align, lineHeight, onClick, ...props }) => {
  if (tag !== "p") {
    return (
      <Span onClick={onClick} className={props.className} color={color} tag={tag} size={size} capital={capital} align={align} lineHeight={lineHeight} font={font} weight={weight}>
        {props.children}
      </Span>
    )
  } else
    return (
      <P onClick={onClick} className={props.className} color={color} tag={tag} size={size} capital={capital} align={align} lineHeight={lineHeight} font={font} weight={weight}>
        {props.children}
      </P>
    )

}
Text.propTypes = {
  capital: PropTypes.oneOf(Object.values(CAPITAL)),
  tag: PropTypes.oneOf(Object.values(TAG)),
  color: PropTypes.string,
  font: PropTypes.oneOf(Object.values(FONT)),
  weight: PropTypes.number,
  size: PropTypes.number,
  lineHeight: PropTypes.number,
  children: PropTypes.node,
  align: PropTypes.string,
  className: PropTypes.string,
};

Text.defaultProps = {
  children: <Text></Text>,
  tag: "span",
  font: FONT.Alternate,
  weight: 400,
  size: 0,
};

