import React from 'react'
import styled from 'styled-components'
import { Text } from '../Text/Text'
import { Link } from '../Link/Link'
import { typography, color } from '../../shared/style'

const AddressLine = ({ content, isMenu, ...props }) => {
    return <>
        {content?.address?.raw?.map((item, i) =>
            <Text
                key={i}
                color={isMenu ? color.primary.white : color.dark.dark90}
                font={typography.type.primary}
                weight={typography.weight.regular}
                lineHeight={26}
            >
                {item.text}
            </Text>)}
        {content?.email.text && !isMenu && <Link textDecoration={props.underlineEmail && "underline"} to={`mailto:${content?.email.text}`}
            color={isMenu ? color.primary.white : color.secondary.danube} lineHeight={26} size={typography.size.s3}>{content?.email.text}</Link>}
    </>
}

export const Address = (props) => {
    return (
        <AddressWrapper isMenu={props.isMenu} >
            <Text
                color={props.isMenu ? color.primary.white : color.dark.dark100}
                font={typography.type.primary}
                weight={typography.weight.bold}
                lineHeight={26}
            >{props.item.title.text}</Text>
            <AddressLine content={props.item} isMenu={props.isMenu} underlineEmail={props.underlineEmail}/>

        </AddressWrapper>
    )
}

const AddressWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: ${(props) => props.isMenu ? "1.5em" : "38px"};
    @media (max-width: 649px) {
        align-items: ${props => !props.isMenu && 'center'};
    }
`;