import React, { useState } from "react";
import styled from "styled-components";
import { color, typography } from "../../shared/style";
import { Icons } from "@storybook/components";
import { redirectPage } from "../../hooks/redirect";

const Menu = styled.ul`
  margin-bottom: 38px;
  flex: 1;
  padding: 0;
  display: flex;
  flex-direction: column;
  height: 100%;
`;
const Item = styled.li`
  margin-bottom: 12px;
  display: flex;
  flex-direction: column;
  list-style: none;
  position: relative;
  /* cursor: pointer; */
  font-family: ${typography.type.primary};
  font-style: normal;
  line-height: 22px;
  text-align: center;
  transition-delay: 0.5s;
  letter-spacing: -0.015em;
  text-transform: uppercase;
`;
const Link = styled.a`
  word-break: break-all;
  display: flex;
  cursor: pointer;
  position: relative;
  text-decoration: none;
  flex-direction: row;
  justify-content: space-between;
  color: ${color.primary.white};
  align-items: center;
  text-transform: capitalize;
  width: fit-content;
  font-size: 20px;
  /* overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; */
  ${(props) =>
    props.isSub &&
    `
		font-size: 18px;
	`}

  &:hover {
    &:after {
      transform: scaleX(1) !important;
    }
  }
  &::after {
    transition: transform 0.3s ease-out, border-color 0.3s ease-out;
    position: absolute;
    display: block;
    bottom: -6px;
    left: 0;
    width: 100%;
    transform: scaleX(0);
    border-top: 2px solid #000;
    content: "";
    padding-bottom: inherit;
    transform-origin: left;
    pointer-events: none;
    bottom: -2px;
    -webkit-transition: -webkit-transform .37s cubic-bezier(.52,.01,.16,1);
    transition: transform .37s cubic-bezier(.52,.01,.16,1);
    border-color: #fff;
  }
`;
const SubLink = styled.a`
  word-break: break-all;
  display: flex;
  cursor: pointer;
  position: relative;
  text-decoration: none;
  flex-direction: row;
  justify-content: space-between;
  color: ${color.primary.white};
  align-items: center;
  text-transform: capitalize;
  width: fit-content;
  font-size: 16px !important;
  width: 80%;
  ${(props) =>
    props.isSub &&
    `
		font-size: 18px;
	`}

  &:hover {
    &:after {
      transform: scaleX(1) !important;
    }
  }
  &::after {
    transition: transform 0.3s ease-out, border-color 0.3s ease-out;
    position: absolute;
    display: block;
    bottom: -6px;
    left: 0;
    width: 100%;
    transform: scaleX(0);
    border-top: 2px solid #000;
    content: "";
    padding-bottom: inherit;
    transform-origin: left;
    pointer-events: none;
    bottom: -2px;
    -webkit-transition: -webkit-transform .37s cubic-bezier(.52,.01,.16,1);
    transition: transform .37s cubic-bezier(.52,.01,.16,1);
    border-color: #fff;
  }
`;
const SubMenu = styled.ul`
  position: relative;
  padding-left: 20px;
  max-height: 0px;
  opacity: 0;
  visibility: visible;
  overflow: hidden;
  transform: translateX(13px);
  overflow-y: scroll;
  /* width: 70%; */
  transition: max-height 0.5s cubic-bezier(0.215, 0.61, 0.355, 1),
    padding 0.25s cubic-bezier(0.215, 0.61, 0.355, 1),
    transform 0.65s cubic-bezier(0.2, 0.8, 0.25, 1),
    opacity 0.65s cubic-bezier(0.2, 0.8, 0.25, 1);

  ${(props) =>
    props.toggle &&
    `
				opacity: 1;
        transform: translateX(0px);
        transition: max-height .5s cubic-bezier(.215,.61,.355,1),padding .25s cubic-bezier(.215,.61,.355,1), transform .65s cubic-bezier(.2,.8,.25,1), opacity .65s cubic-bezier(.2,.8,.25,1);
        max-height:500px;
    `}
`;
const SubItem = styled.li`
  text-align: left;
  font-family: ${typography.type.primary};
  list-style: none;
  position: relative;
  font-size: ${typography.size.s3} px;
  letter-spacing: -0.015em;
  color: ${color.netraul.black100};
  margin-top: 5px;
`;
const Icon = styled(Icons)`
  cursor: pointer;
  width: 25px;
  transform: ${(props) =>
    props.toggle ? "rotate(180deg)" : "rotate(0deg)"} !important;
  transition: transform 1s;
  position: absolute;
  right: 0;
  color: white;
  left: 70%;
`;
const Div = styled.div`
  overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    /* width: 75%; */
`
const MenuItem = ({ children, menu, onClick }) => {
  const [toggle, setToggle] = useState(false);
  return (
    <Item>
      {menu && menu ? (
        <>
          <Link onClick={() => setToggle(!toggle)}>{children}</Link>
          <Icon onClick={() => setToggle(!toggle)} toggle={toggle} icon={"arrowdown"}></Icon>
          {
            <SubMenu toggle={toggle}>
              {menu?.map((item, i) => (
                <SubItem key={i}>
                  <SubLink isSub={true} href={`/${item?.city1?.document?.uid}`}>
                    <Div>{item?.city1?.document?.data?.city?.text}, {item?.city1?.document?.data?.country?.text}</Div>
                  </SubLink>
                </SubItem>
              ))}
            </SubMenu>
          }
        </>
      ) : (
        <Link onClick={onClick}>{children}</Link>
      )}
    </Item>
  );
};
export const SideMenu = ({ data, menuCities }) => (
  <Menu>
    {data?.map((item, i) => (
      <MenuItem
       key={i} 
       onClick={()=>
        redirectPage({
            url: item?.link?.url,
            type: item?.link?.type,
            link_type: item?.link?.link_type
        })
    } 
       menu={item?.label?.text === "Cities" || item?.label?.text === "Our Houses" && menuCities}
      >
        {item?.label?.text}
      </MenuItem>
    ))}
  </Menu>
);
