import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { color } from '../../shared/style'

/**
 * Primary UI component for user interaction
 */
const FONT = {
  Oxygen: 'Oxygen',
  Alternate: 'Alternate Gothic No1 D',
}

const CAPITAL = {
  uppercase: "uppercase",
  lowercase: "lowercase",
  capitalize: "capitalize",
}

export const Link = ({ children, secondary, to, size, type, blank,onClick, ...props }) => {
  switch (type) {
    case "mail":
      return (
        <StyledLink
          href={`mailto:${children}`}
          size={size}
          {...props}
          lastChild={children === "Privacy Policy"}
        >
          {children}
        </StyledLink>
      );
    case "tel":
      return (
        <StyledLink
          href={`tel:${children}`}
          size={size}
          {...props}
          lastChild={children === "Privacy Policy"}
        >
          {children}
        </StyledLink>
      );
    default:
      return (
        <StyledLink
          onClick={onClick}
          href={to}
          target={blank && "_blank"}
          size={size}
          {...props}
          lastChild={children === "Privacy Policy"}
        >
          {children}
        </StyledLink>
      );
  }
  // return (
  //   <StyledLink
  //     href={to}
  //     size={size}
  //     {...props}
  //     lastChild={children === 'Privacy Policy'}
  //   >
  //     {children}
  //   </StyledLink >
  // );
};

const StyledLink = styled.a`
  font-size: ${props => props.size && props.size}px;
  text-transform: ${props => props.capital};
  color: ${props => props.color && props.color};
  font-family: ${props => props.fontFamily && props.fontFamily};
  font-weight: ${props => props.weight && props.weight};
  text-decoration: ${props => props.textDecoration && props.textDecoration};
  line-height: ${props => props.lineHeight && props.lineHeight}px;
  cursor: pointer;
  letter-spacing: 0.4px;
  &:hover {
    color: #798EA9;
  }
/* &:last-child {
  border-right: none;
  padding-right: 0;
} */
`

Link.propTypes = {
  capital: PropTypes.oneOf(Object.values(CAPITAL)),
  color: PropTypes.string,
  size: PropTypes.number,
  weight: PropTypes.number,
  fontFamily: PropTypes.oneOf(Object.values(FONT)),
  textDecoration: PropTypes.oneOf(["none", "underline"]),
  lineHeight: PropTypes.number
};

Link.defaultProps = {
  color: color.secondary.danube,
  size: 16,
  weight: 400,
  fontFamily: FONT.Oxygen,
  textDecoration: 'none',
  lineHeight: 26
};
