
import React from 'react'
import PropTypes from 'prop-types';
import styled from "styled-components";
const Link = styled.a`
  width: 150px;
  height: 59px;
  object-fit: contain;
  cursor: pointer;
`
const Logo = styled.img`
  width: 150px;
  height: 50px;
`
export const HomeLogo = ({ logo }) => (
  <Link href="/">
    <Logo src={logo} />
  </Link>
)
HomeLogo.propTypes = {
  logo: PropTypes.string
};

HomeLogo.defaultProps = {
};
