import React from "react"
import styled from "styled-components"
import { redirectPage } from "../../hooks/redirect";
import { color, typography } from "../../shared/style"

const Menu = styled.ul`
  flex: 1;
  @media (max-width: 1200px) {
    display: none;
  }
  padding: 0;
  display: flex;
  margin-left: 40px;
  height: 100%;
`;
const Item = styled.li`
    &:before {
        content: "";
        border-left: 2px solid #FFFFFF;
        opacity: 0.15;
        height: 20px;
    }
    display: flex;
    align-items: center;
    list-style: none;
    position: relative;
    font-family: ${typography.type.primary};
    font-style: normal;
    line-height: 22px;
    text-align: center;
    transition-delay: 0.5s;
    letter-spacing: -0.015em;
    text-transform: uppercase;
    & div {
        transition-delay: 0.3s;
    }
    & li {
        transition-delay: 0.2s;
    }
    &:hover div {
          opacity: 1;
          top: 100%;
          left: 0;
    }
    &:hover li {
        opacity: 1;
        transform: translateY(0px);
    }
`
const Link = styled.a`
    padding: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 25px;
    ${props =>
        props.isSub ? `
            border: none !important;
            font-size: ${typography.size.s1}px;
            color: ${color.netraul.black100};
            &:hover {
                color: ${color.primary.white};
            }
        ` : `
        font-size: ${typography.size.s1}px;
        color: ${color.primary.white};
        opacity: 0.7;
        &:hover {
            opacity: 1;
            color: ${color.primary.white};
        }`
    }
    text-transform: uppercase;
    text-decoration: none;
    cursor: pointer;
    font-weight: ${typography.weight.bold};
    letter-spacing: 0.4px;
`
const SubMenu = styled.ul`
    position: absolute;
    top: 100%;
    left: 0;
    padding: 20px;
    background: ${color.primary.white};
    box-shadow: 0 19px 35px rgb(0 0 0 / 11%);
    overflow: auto;
    max-height: 65vh;
`
const SubItem = styled.li`
    text-align: left;
    font-family: ${typography.type.primary};
    list-style: none;
    position: relative;
    font-size: ${typography.size.m1}px;
    background: ${color.primary.white};
    letter-spacing: -0.015em;
    color: ${color.netraul.black100};
    opacity: 0;
    transform: translateY(13px);
    transition: transform .65s cubic-bezier(.2,.8,.25,1),opacity .35s cubic-bezier(.2,.8,.25,1);
    &:hover {
        color: ${color.primary.white};
        background: ${color.primary.blue};
    }
`
const WraperMenu = styled.div`
    width: 20em;
    opacity: 0;
    position: absolute;
    top: -999em;
    transition-delay: 0.6s;
`
const MenuItem = ({ children, menu, link, onClick }) => {

    return (
        <Item>
            {menu && menu ? <>
                <Link>{children}</Link>
                {
                    <WraperMenu>
                        <SubMenu>
                            {menu?.map((item, i) =>
                                <SubItem key={i}><Link isSub={true} href={`/${item?.city1?.document?.uid}`}>{item?.city1?.document?.data?.city?.text}, {item?.city1?.document?.data?.country?.text}</Link></SubItem>
                            )}
                        </SubMenu>
                    </WraperMenu>
                }
            </>
                : <Link onClick={onClick}>{children}</Link>
            }
        </Item>
    )
}
export const HomeMenu = ({ menuList, menuCities }) => {
    return (

        <Menu>
            {menuList?.map((item, i) =>
                <MenuItem 
                    key={i} 
                    // link={item?.link?.type} 
                    onClick={()=>
                        redirectPage({
                            url: item?.link?.url,
                            type: item?.link?.type,
                            link_type: item?.link?.link_type
                        })
                    }
                    menu={item?.label?.text === "Cities" || item?.label?.text === "Our Houses" && menuCities}
                >
                    {item?.label?.text}
                </MenuItem>
            )}
        </Menu>
    )
}
