import React from 'react'
import './../styles/reset.css'
import './../styles/common.css'
import './../styles/style.css'
import styled from 'styled-components'
import { color } from '../shared/style'
// import CookieNotice from 'gatsby-cookie-notice'

const WrapperLayout = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: ${color.primary.blue};
  position: absolute;
  overflow: hidden;
  @media (min-width: 1001px) {
    min-height: 100vh;
  }
`;

const Container = styled.div`
  width: 100vw;
  transform: ${props => props.toggle ? `scale(.835) translateX(-466px) translateZ(0)!important` : ''};
  transform-origin: right;
  transition: transform .8s cubic-bezier(.2,1,.3,1);
  background-color: ${props => props.theme.bgColor};
  height: 100%;
  overflow-y: ${props => props.toggle ? 'hidden' : 'scroll'};
  @media (max-width: 450px) {
    transform: ${props => props.toggle ? `scale(.84) translateX(-93vw) translateZ(0)!important;` : ''};
  }
`;
const HeaderContainer = styled.div`
    position: ${props => props.toggle ? 'sticky' : 'fixed'};
    width: 100vw;
    z-index: 99;
    top: 0;
    left: 0;
    right: 0;
`;
const FooterContainer = styled.div`
  min-height: 600px;
  background-color:${color.primary.blue05};
`;

const Layout = ({ children, header, footer, toggle, toggleLayout, sidebar, seo }) => (
  <WrapperLayout toggle={toggle}>
    <Container toggle={toggle}>
      <HeaderContainer toggle={toggle}>{header}</HeaderContainer>
      {seo}
      {children}
      <FooterContainer>{footer}</FooterContainer>
    </Container>
    {sidebar}
  </WrapperLayout>
)

export default Layout;