import { useStaticQuery, graphql } from "gatsby"

export const useSiteMetadata = () => {
  const { allPrismicFooter, allPrismicHeader, allPrismicSeo, allDataJson } = useStaticQuery(
    graphql`
      query MyQuery {
        allPrismicFooter {
          nodes {
            data {
              site_logo {
                alt
                url
              }
              description {
                html
                text
                raw
              }
              footer_copyright {
                html
                text
                raw
              }
              button_label {
                text
                html
                raw
              }
              button_link {
                url
                type
                link_type
              }
              address {
                title {
                  html
                  text
                  raw
                }
                email {
                  html
                  text
                  raw
                }
                address {
                  html
                  text
                  raw
                }
              }
              body {
                ... on PrismicFooterBodyMenu {
                  primary {
                    title {
                      html
                      text
                      raw
                    }
                  }
                  items {
                    link {
                      url
                      type
                      link_type
                    }
                    label {
                      html
                      text
                      raw
                    }
                  }
                }
              }
              body1 {
                ... on PrismicFooterBody1Menu {
                  primary {
                    title {
                      html
                      text
                      raw
                    }
                  }
                  items {
                    city {
                      document {
                        ... on PrismicLocationSpace {
                          data {
                            city {
                              html
                              text
                              raw
                            }
                          }
                          uid
                        }
                      }
                    }
                  }
                }
              }
              body2 {
                ... on PrismicFooterBody2Navigation {
                  items {
                    label {
                      html
                      text
                      raw
                    }
                    link {
                      url
                      type
                      link_type
                      uid
                    }
                  }
                }
              }
              body3 {
                ... on PrismicFooterBody3SocialSite {
                  items {
                    icon {
                      url
                      alt
                    }
                    link {
                      url
                      type
                      link_type
                    }
                  }
                }
              }
            }
          }
        }
        allPrismicHeader {
          nodes {
            data {
              site_logo {
                url
                alt
              }
              button_label {
                html
                text
                raw
              }
              button_link {
                url
                type
                link_type
              }
              main_navigation {
                label {
                  html
                  text
                  raw
                }
                link {
                  url
                  type
                  link_type
                }
              }
              city {
                city1 {
                  document {
                    ... on PrismicLocationSpace {
                      uid
                      data {
                        city {
                          html
                          text
                          raw
                        }
                        country {
                          html
                          text
                          raw
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        },
        allPrismicSeo {
          edges {
            node {
              data {
                body {
                  ... on PrismicSeoBodyGeneralCard {
                    primary {
                      description {
                        text
                      }
                      title {
                        text
                      }
                      favicon {
                        url
                        thumbnails {
                          large_image {
                            url
                          }
                        }
                      }
                      robot {
                        text
                      }
                      googlebot {
                        text
                      }
                      bingbot {
                        text
                      }
                    }
                    slice_type
                  }
                  ... on PrismicSeoBodyTwitterCard {
                    primary {
                      card_type
                      description {
                        text
                      }
                      image {
                        url
                        thumbnails {
                          large_image {
                            url
                          }
                        }
                      }
                      twitter_handle
                      title {
                        text
                      }
                    }
                    slice_type
                  }
                  ... on PrismicSeoBodyOpenGraph {
                    id
                    slice_type
                    primary {
                      og_description {
                        text
                      }
                      og_image {
                        url
                        thumbnails {
                          large_image {
                            url
                          }
                        }
                      }
                      og_image_height {
                        text
                      }
                      og_image_width {
                        text
                      }
                      og_locale {
                        text
                      }
                      og_site_name {
                        text
                      }
                      og_title {
                        text
                      }
                      og_type {
                        text
                      }
                      og_url {
                        url
                        type
                      }
                    }
                  }
                }
              }
            }
          }
        },
        allDataJson {
          nodes {
            map {
              points
              X_MIN
              Y_MIN
              X_MAX
              Y_MAX
              X_RANGE
              Y_RANGE
              grid
              height
              width
              ystep
              region {
                lng {
                  min
                  max
                }
                lat {
                  min
                  max
                }
              }
            }
            map_about {
              points
              X_MIN
              Y_MIN
              X_MAX
              Y_MAX
              X_RANGE
              Y_RANGE
              grid
              height
              width
              ystep
              region {
                lng {
                  min
                  max
                }
                lat {
                  min
                  max
                }
              }
            }
            header {
              data {
                navigation {
                  menu {
                    label
                    link
                    submenu {
                      data {
                        city {
                          text
                        }
                        country {
                          text
                        }
                      }
                      uid
                    }
                  }
                }
              }
            }
          }
        }
      }
      `
  )

  return {
    allPrismicFooter,
    allPrismicHeader,
    allPrismicSeo,
    allDataJson
  }
}