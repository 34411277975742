import React from 'react'
import styled from 'styled-components'
import { Link } from '../Link/Link'
import arrow from '../../images/arrow_right.svg'

const ListItem = ({ children, className, to, noLineHeight, lineHeight,onClick, ...props }) => {
    return (
        <Li className={className} noLineHeight={noLineHeight} border={props.border} {...props}>
            <WrapperLink border={props.border}>
                <Link
                    to={to}
                    variant="list"
                    color={props.colorItemSeemore ? props.colorItemSeemore : props.colorItem}
                    size={props.sizeItem}
                    lineHeight={lineHeight || 26}
                    onClick={onClick}
                >
                    {/* {children} */}
                    {props.isIcon ? <img src={children} alt="alt"></img> : children}
                </Link>
            </WrapperLink>
            {props.isSeemore && <Arrow src={arrow}></Arrow>}
        </Li>
    )
}

const Li = styled.li`
    width: ${props => props.numberOfCol ? 100 / props.numberOfCol + "%" : 'fit-content'};
    list-style:none;
    text-align: start;
    ${props => !props.noLineHeight && `line-height: 36px`};
    padding-right: ${props => props.border && '14px'};
    position: relative;
    display: flex;
    padding-bottom: 10px;
    padding-right: ${props => props.type === "social" ? "20px" : "24px"};
    box-sizing: border-box;
    align-items: ${props => !props.numberOfCol ? "center" : "flex-start"};
    &:after{
        padding-left: ${props => props.border && '24px'};
        border-right: ${props => props.border && '1px solid #D2D9E2'};
        content: "";
        height: 15px;
    }
    &:first-child{
        padding-left: 0;
    }
    &:last-child:after{
        border-right: none;
    }
    @media(max-width: 1100px) and (min-width:1000px) {
        ${props => props.numberOfCol === 5 && `width: ${100 / 3}%` };
    }
    @media(max-width: 1000px) and (min-width:650px) {
        line-height: 26px;
        width: ${props => props.numberOfCol === 3 && "50%" };
    }
    @media(max-width: 649px) {
        line-height: 26px;
        width: ${props => props.widthMobile && props.widthMobile};
    }
`;
const WrapperLink = styled.div`
    line-height: normal;
`

const Arrow = styled.img`
    margin-left: 5px;
`

const StyledListItem = styled(ListItem)`
`

export default StyledListItem